<template>
  <div class="main-content-container container-fluid h-100 px-4">
    <b-row class="h-100" no-gutters>
      <b-col class="mx-auto" lg="4" md="4" style="margin-top: 2vw !important;">
        <b-overlay :show="isValidating">
          <b-card>
            <b-card-header class="pt-5 pb-0 text-center">
              <h2>Two-Factor Authentication</h2>
              <h6 class="m-0">Enter the code generated by your authenticator app.</h6>
            </b-card-header>
            <b-card-body>
              <div class="text-center">
                <i class="material-icons icon mb-4">lock</i>
                <CodeInput style="width: auto" :loading="false" class="input" v-on:complete="validate"/>
                <h6 class="p-3" :class="message.class">{{ message.text }}</h6>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {post} from "@/services/api";

export default {
  name: "TwoFactor",
  components: {CodeInput},
  data: () => ({
    message: {
      class: 'text-muted',
      text: 'Enter your code'
    },
    isValidating: false
  }),
  methods: {
    validate(token) {
      this.isValidating = true
      post('auth/validate-two-factor', {id: this.$store.getters.getUserId, token})
        .then(({data}) => {
          if (data) {
            this.$store.commit('setUserAtt', {twoFactorPass: true})
            this.$router.push('dashboard')
          } else {
            this.message = {class: 'text-danger', text: 'Code invalid'}
          }
        }).finally(() => this.isValidating = false)
    }
  }
}
</script>

<style scoped>
.icon {
  font-size: 8em;
}
</style>
